import { Link } from "gatsby"
import React from "react"

import logo from "../../images/logo.png"

import "./header.less"
import HeaderContact from "./headerContact"



const Header = (props) => (
  <header className="header">
    <Link className="logo" to="/">
      <img src={logo} alt="Master Max logo" className="logo" />
    </Link>
    <HeaderContact contactData={props.contactData} />
  </header>
)

export default Header
