import React, {useState} from 'react'
import logo from '../../images/logo.png'
import 'bootstrap/dist/css/bootstrap.min.css'
import styled from 'styled-components'
import '../../styles/layout.less'
import './navbar.less'
import { Link } from 'gatsby';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,

  } from 'reactstrap';


const Logo = styled.img`` 



const NavMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
  
    return (
        <Navbar className='navbar' expand="md">
          <NavbarBrand href="/"><Logo alt="Master Max logo" className="logo logo-hide" src={logo} ></Logo> </NavbarBrand>
          <NavbarToggler className="custom-toggler" onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <Link activeClassName="nav-link--active" className="grow nav-link" to="/about">O nas</Link>
                <div className="nav-item--underline"></div>
              </NavItem>
              <NavItem>
                <Link activeClassName="nav-link--active" className="grow nav-link" to="/oferta/">Oferta</Link>
                <div className="nav-item--underline"></div>
              </NavItem>
              <NavItem>
                <Link activeClassName="nav-link--active"  className="nav-link grow" to="/uslugi/">Usługi</Link>
                <div className="nav-item--underline"></div>
              </NavItem>
              <NavItem>
                <Link activeClassName="nav-link--active"  className="nav-link grow" to="/kontakt/">Kontakt</Link>
                <div className="nav-item--underline"></div>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
    );
}
    



export default NavMenu;