import React from "react"
import { Link } from "gatsby"

import "../../styles/layout.less"
import "./footer.less"
import PhoneIcon from "../icons/phone-icon"
import MailIcon from "../icons/mail-icon"
import ClockIcon from "../icons/clock-icon"

class Footer extends React.Component {
  state = {
    auth: false,
    slide: 0,
    lastScrollY: 0,
    isScrolling: false,
  }
  timeout = null

  componentWillMount() {
    if (typeof window !== `undefined`) {
      window.addEventListener("scroll", this.handleScroll)
    }
  }

  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener("scroll", this.handleScroll)
    }
  }

  handleScroll = () => {
    this.setState({ isScrolling: true })

    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.setState({ isScrolling: false, slide: "0px" })
    }, 200)

    if (this.state.isScrolling) {
      this.setState({ slide: "64px" })
    }
  }

  render() {
    return (
      <footer
        className="footer"
        style={{
          transform: `translateY(${this.state.slide})`,
          transition: "transform 150ms linear",
        }}
      >
        <span className="footer-small-screen">
          <a
            href={`mailto:${this.props.contactData[0].text}`}
            className="contact-column"
          >
            <MailIcon />
            <div className="contact-data">{this.props.contactData[0].text}</div>
          </a>
          <a
            href={`tel:${this.props.contactData[1].text}`}
            className="contact-column"
          >
            <PhoneIcon />
            <div className="contact-data">{this.props.contactData[1].text}</div>
          </a>
          <a href="/" className="contact-column">
            <ClockIcon />
            <div className="contact-data">{this.props.contactData[2].text}</div>
          </a>
        </span>
        <span className="footer-large-screen">
          <span className="footer-company-name">MASTER MAX</span>
          <span className="footer-links">
            <Link className="footer-link" to="/">
              <div className="footer-link-bullet"></div>
              DORABIANIE KLUCZY MIESZKANIOWYCH
            </Link>
            <Link className="footer-link" to="/oferta">
              <div className="footer-link-bullet"></div>
              PRODUKCJA SYSTEMÓW MASTER KEY
            </Link>
            <Link className="footer-link" to="/uslugi">
              <div className="footer-link-bullet"></div>
              USŁUGI SZEWSKIE
            </Link>
          </span>
        </span>
      </footer>
    )
  }
}

export default Footer
