import React from "react"

import MailIcon from "../icons/mail-icon"
import PhoneIcon from "../icons/phone-icon"
import ClockIcon from "../icons/clock-icon"

const HeaderContact = props => (
  <span className="top-contact-container">
    <a
      href={`mailto:${props.contactData[0].text}`}
      className="top-contact-link"
    >
      <MailIcon />

      <div className="top-contact-description">
        <div className="contact-data">{props.contactData[0].text}</div>
        <div className="top-contact-cta">NAPISZ</div>
      </div>
    </a>
    <a href={`tel:${props.contactData[1].text}`} className="top-contact-link">
      <PhoneIcon />

      <div className="top-contact-description">
        <div className="contact-data">{props.contactData[1].text}</div>
        <div className="top-contact-cta">ZADZWOŃ</div>
      </div>
    </a>
    <a href={`tel:${props.contactData[1].text}`} className="top-contact-link">
      <ClockIcon className="top-icon" />

      <div className="top-contact-description">
        <div className="contact-data">{props.contactData[2].text}</div>
        <div className="top-contact-cta">ZAPRASZAMY</div>
      </div>
    </a>
    <a href="https://www.facebook.com/szewc.zaspa" target="_blank" rel="noreferrer">
      <svg
        className="fb-icon grow"
        fill="rgb: 255, 255, 0"
        height="30"
        viewBox="0 0 512 512"
        width="30"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m512 256c0-141.4-114.6-256-256-256s-256 114.6-256 256 114.6 256 256 256c1.5 0 3 0 4.5-.1v-199.2h-55v-64.1h55v-47.2c0-54.7 33.4-84.5 82.2-84.5 23.4 0 43.5 1.7 49.3 2.5v57.2h-33.6c-26.5 0-31.7 12.6-31.7 31.1v40.8h63.5l-8.3 64.1h-55.2v189.5c107-30.7 185.3-129.2 185.3-246.1z" />
      </svg>
    </a>
  </span>
)

export default HeaderContact
