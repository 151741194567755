import React from "react"
import PropTypes from "prop-types"

import Header from "./header/header"
import "../styles/layout.less"

import Footer from "./footer/footer"
import NavMenu from "./navbar/navbar"



const Layout = ({ children }) => {
  

const contactData = [
    {
        "label": "email",
        "text": "info@master-max.pl"
    },
    {
        "label": "phone",
        "text": "731 506 950"
    },
    {
        "label": "opening-hours",
        "text": "10:00-18:00"
    }
  ];

  return (
    <>
      <Header contactData={contactData}/>
      <NavMenu />
      <main>{children}</main>
      <Footer contactData={contactData}/>
      
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
